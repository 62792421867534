import { Modal, ListGroup, Spinner } from 'react-bootstrap';
import CoinsItem from './CoinsItem';
import { useQuery } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { DynamoNumberType, DynamoStringType } from './types/dynamo-types';

export interface ModalTypes {
  showModal: boolean
  toggleModal: () => void
}

interface CoinPricesTypes {
  id: DynamoStringType
  amount: DynamoNumberType
  coins: DynamoNumberType
}

interface CoinItemTypes {
  Items: CoinPricesTypes[]
}

const CoinsModal = ({showModal, toggleModal}: ModalTypes) => {
  
  const api = useApiContext();

  const { data, isError, isLoading, isFetching, isSuccess } = useQuery<CoinItemTypes>('coins-data', async () => {
    return api.getCoinPrices().then(res => res).catch(err => { throw new Error(err) });
  }, {
    refetchOnWindowFocus: false,
    retry: 2
  });

  if(isSuccess) return (
    <>
    {console.log('data: ', data)}
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title className="roboto-slab-bold">Get you some coins!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {console.log('data: ', data)}
            {/* map through list of coin prices */}
            {data?.Items.sort((a, b) => a.coins.N - b.coins.N).map(({id, amount, coins}) => <ListGroup.Item key={id.S}><CoinsItem numberOfCoins={coins.N} amount={amount.N} /></ListGroup.Item>)}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );

  else if (isLoading || isFetching) return <Spinner animation="grow" />

  else if (isError) return <h1>There seems to be an error, please check back later!</h1>

  else return <h1>An error seems to have occurred, please reload the page.</h1>
}

export default CoinsModal;