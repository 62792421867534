import { createContext, FC, useState, useLayoutEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useCognitoContext } from './hooks/cognitoContext';

export interface CognitoUserAttributes {
  sub: string // cognito user id
  given_name: string
  family_name: string
  email: string
  email_verified: string
}

export interface AppContextTypes {
  auth: boolean
  logout?: () => void
  login?: () => void
  authAttributes: CognitoUserAttributes | undefined
  // jwt: string // to have jwt for higher level
}

export const AppContext = createContext<AppContextTypes>({auth: false, authAttributes: undefined});

const AppProvider: FC = ({children}) => {

  const history = useHistory();
  const cognito = useCognitoContext();
  const [auth, setUser] = useState(false);
  const authAttributes = useRef<CognitoUserAttributes>({} as CognitoUserAttributes);

  const login = useCallback(() => {
    // call cognito here using react query
    setUser(true);
    history.push('/');
  }, [history]);

  const logout = () => {
    // invalidate react query to cognito here?
    setUser(false);
    cognito.logout();
    history.push('/');
  }

  useLayoutEffect(() => {
    cognito.getUserAttrs()
      .then( async res => {
        res?.map(({Name, Value}) => authAttributes.current[`${Name}` as keyof CognitoUserAttributes] = Value);
        login();
      }).catch(err => console.error('user attributes error: ', err));
  }, [auth, cognito, login]);

  return (
    <AppContext.Provider value={{auth, login, logout, authAttributes: authAttributes.current}}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;