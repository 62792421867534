import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useAppContext } from '../hooks/providerContext';

const Home = () => {
  const app = useAppContext();
  const history = useHistory();

  const sendTo = (page: string) => {
    switch (page) {
      case 'coins':
        !app.auth ? history.push('/itzli/Register') : history.push('/Dashboard');
        break;
      case 'articles':
        history.push('/itzli/Articles');
        break;
      default:
        break;
    }
  } 

  return (
    <section className="container-lg mt-5">
      <aside className="justify-content-center">
        <h1 className="display-1">Ítzli <span className="display-4">[eat-see]</span></h1>
      </aside>
      <p className="lead">Organize in your own voice.</p>
      <p className="lead">An interactive platform that connects Latiné creators to their audience and provides monetization opportunities across the Latinésphere.</p>

      <hr />
      <aside className="d-flex justify-content-between pt-2">
        <blockquote className="blockquote p-2">
          Monetary support for content creators through our Ítzli Coin system!
          <br />
          <Button className="mt-4" variant="dark" size="sm" onClick={() => sendTo('coins')}>Buy Coins</Button>
        </blockquote>
        <blockquote className="blockquote p-2">
          Create written, verbal, or visual content (latter two coming soon!)
          <br />
          <Button className="mt-4" variant="dark" size="sm" onClick={() => sendTo('coins')}>Sign up to contribute</Button>
        </blockquote>

        <blockquote className="blockquote p-2">
          Support other content creators by giving of the Ítzli Coins that you earn through your own journey!
          <br />
          <Button className="mt-4" variant="dark" size="sm" onClick={() => sendTo('articles')}>Articles</Button>
        </blockquote>
      </aside>
    </section>
  );
}

export default Home;