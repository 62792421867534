import { FormEvent, ChangeEvent, useState } from 'react';
import { Form, Modal, Button, Toast } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { useAppContext } from '../hooks/providerContext';
import { useLocation } from 'react-router';
import '../style/css/PublishModal.css';

interface CoinTransferTypes {
  show: boolean
  handleClose: () => void
}

interface CoinTransferMutationTypes {
  donorId: string | undefined
  recieverId: string
}

interface CoinTransferLocationTypes {
  author: string
}

const CoinTransfer = ({show, handleClose}: CoinTransferTypes) => {
  const { state: { author } } = useLocation<CoinTransferLocationTypes>();
  const app = useAppContext();
  const api = useApiContext();
  const [rangeValue, setRangeValue] = useState(5);

  const mutatation = useMutation(async ({donorId, recieverId}: CoinTransferMutationTypes) => {
    console.log('is same: ', donorId, author);
    if(!donorId || donorId === author) throw new Error('SameAuthor');
    await api.coinExchange(donorId, recieverId, rangeValue).then(res => {
      console.log('res: ', res);
      if(res === 'NotEnoughCoins') throw new Error(res);
      else return res;
    }).catch(err => {throw new Error(err)});
  }, {
    onSuccess: () => {
      console.log('success!');
      alert('¡Chivaso! You did it! Thank you for supporting this creative!');
      handleClose();
    },
    onError: (e: Error) => {
      console.log('error! ', e.message);
      switch (e.message) {
        case 'SameAuthor':
          alert('You can\'t support yourself, because you\'re already doing that 😇');
          handleClose();
          break;
        case 'Error: NotEnoughCoins':
          alert('Looks like you don\'t have enough coins 😭. Please lower the amount and try again.');
          break;
        default:
          break;
      }
    },
    onSettled: () => console.log('settled')
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setRangeValue(e.target.value as unknown as number);
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!app.auth) {
      alert('It looks like you need to login/register before you can support this creator!');
      return;
    }
    mutatation.mutate({donorId: app.authAttributes?.sub, recieverId: author})
  }

  return (
    <>
      <Modal
        contentClassName="modal-dark"
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Send this creator coins!
          </Modal.Title>
          <Form.Text className="text-muted">
            ¡Poco a poco se llega lejos!
          </Form.Text>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Label>Select amount of coins you'd like to give!</Form.Label> <br />
            <input style={{width: '100%'}} type="range" name="coins" defaultValue={rangeValue} min={1} max={10000} onChange={handleChange} /> | <Form.Label>{rangeValue}</Form.Label>
            <br />
            <Button type="submit" variant="outline-light">¡Vamos!</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-light" onClick={handleClose}>No Thanks!</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CoinTransfer;