import { CSSProperties } from 'react';

export const containerMid: CSSProperties = {
  position: 'fixed',
  bottom: '38vh',
  left: '26vw',
}

export const containerInGrid: CSSProperties = {
  display: 'inline-grid',
  width: '24rem',
  height: '24rem',
  margin: '0.5rem'
}

export const containerLg: CSSProperties = {
  textAlign: 'center',
  paddingTop: '10rem',
  paddingBottom: '10rem'
}