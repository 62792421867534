import { FormEvent, FocusEvent, useRef, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';
import { Button, Form, Modal } from 'react-bootstrap';
import { useApiContext } from '../hooks/apiContext';
import { useAppContext } from '../hooks/providerContext';
import { CoinsTypes } from './CoinsItem';
import { CustomerItemTypes } from '../pages/Dashboard';
import { useHistory, useLocation } from 'react-router-dom';

interface BillingDetailsTypes {
  name: string
  email: string
  phone: string
}

export interface PaymentTypes {
  data: CustomerItemTypes | undefined
}

const Payment = ({data}: PaymentTypes) => {
  const app = useAppContext();
  const api = useApiContext();
  const history = useHistory();
  const { state: {numberOfCoins, amount} } = useLocation<CoinsTypes>();
  const stripe = useStripe();
  const elements = useElements();
  const billingDetails = useRef({} as BillingDetailsTypes);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleInput = (e: FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    billingDetails.current[`${e.target.name}` as keyof BillingDetailsTypes] = e.target.value;
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const saveCardInput = e.currentTarget.elements.namedItem('save-info') as HTMLInputElement;
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    if(!data || (data.Items && !data.Items[0].customerId.S)) {
      console.log('customer id no');
      const cardElement = elements.getElement(CardElement);
      // Use your card Element with other Stripe.js APIs
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        billing_details: billingDetails.current
      });
  
      if (error) {
        alert(error.message);
      } else {
        console.log('[PaymentMethod]', paymentMethod);
        try {
          const result: PaymentIntent = await api.makePurchase(paymentMethod, saveCardInput ? saveCardInput.checked : false, numberOfCoins, amount, app.authAttributes?.sub, '', '');
          console.log('payment res: ', result);
          if(result.status === 'succeeded') setShowSuccess(true);
        } catch (error) {
          console.error('error: ', error);
        }
      }
    } else {
        console.log('customer id yes');
        try {
          const result: PaymentIntent = await api.makePurchase(undefined, saveCardInput ? saveCardInput.checked : false, numberOfCoins, amount, app.authAttributes?.sub, data?.Items[0].customerId.S, data?.Items[0].paymentMethodId.S);
          console.log('payment res: ', result);
          if(result.status === 'succeeded') setShowSuccess(true);
        } catch (err) {
          console.error('err: ', err);
        }
    } 
    
  };

  const handleDialogClose = () => {
    setShowSuccess(false);
    history.push('/dashboard');
  }

  return (
    <>
    {console.log('data: ', data)}
      <form onSubmit={handleSubmit} style={{padding: '5rem 10rem'}} >
        <h1>{numberOfCoins} Ítzli Coins for ${amount/100}</h1>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control name="name" type="text" placeholder="Name" onBlur={handleInput} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control name="email" type="email" placeholder="Email" onBlur={handleInput} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone</Form.Label>
          <Form.Control name="phone" type="phone" placeholder="Phone" onBlur={handleInput} />
        </Form.Group>
        {!data || (data.Items && !data.Items[0].customerId.S) ? 
          // If card info saved in Stripe, then have "Card already saved message, give new payment option"
          // do not render this if data comes back with saved card type
          <aside style={{border: '1px solid lightgray', padding: '1rem'}}>
            <Form.Label>Credit Card Information</Form.Label>
            <CardElement options={{
              style: {
                base: {
                  fontSize: '16px',
                  fontWeight: 'bold',
                  fontFamily: 'Roboto Slab, serif',
                  fontSmoothing: "antialiased",
                  ":-webkit-autofill": {
                    color: "#fce883"
                  },
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }} />
            {/* do not render these two if data comes back with saved card type */}
            <input className="mt-3" type="checkbox" name="save-info" />
            <span className="ml-2">Save payment info</span>
          </aside> 
          :
          <h4>Your payment is already saved. </h4>
        }
        <br />
        <Button variant="dark" type="submit">Pay</Button>
        <br />
      </form>
      {showSuccess && (
        // show modal here
        <Modal show={showSuccess} size="lg" onHide={handleDialogClose} backdrop="static">
          <Modal.Title className="roboto-slab-bold" style={{marginLeft: '1rem'}}>Thank you for your purchase!</Modal.Title>
          <Modal.Body>
            <p>We're excited to see how you'll be rewarding Ítzli's content creators!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="roboto-slab" variant="dark" onClick={handleDialogClose}>Vamos! <span role="img" aria-label="fire" >🔥</span></Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default Payment;