import { CSSProperties, useState } from 'react';
import { Button } from 'react-bootstrap';
import CoinsModal from './CoinsModal';
import CreatorRegister from './CreatorRegister';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { containerLg, containerMid, containerInGrid } from '../style/ts/containers';
import { PaymentTypes } from './Payment';
import { useApiContext } from '../hooks/apiContext';
import { useAppContext } from '../hooks/providerContext';
import UserArticles from './UserArticles';

interface DashTypes extends PaymentTypes {
  sub: string | undefined
}

const DashHome = ({data, sub}: DashTypes) => {
  const api = useApiContext();
  const app = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const toggleModal = () => {
    setShowModal(sm => !sm);
  }
  const toggleCreatorModal = () => {
    setShowRegister(sr => !sr);
  }

  const mutation = useMutation( async () => {
    console.log('data dash: ', data);
    
    await api.removeCustomerId(data?.Items[0].customerId.S, sub).then(res => res).catch(err => {throw new Error(err)});
  }, {
    onSuccess: () => {console.log('success!')},
    onError: () => alert('There was an error with grabbing your data. Please try again later.')
  });

  const removeCustomerId = () => {
    const willRemove = window.confirm('Are you sure you want to remove your current payment method?')
    willRemove ? mutation.mutate() : console.log('cancelled');
  }

  const side: CSSProperties = {
    position: 'fixed',
    backgroundColor: '#333B3F',
    width: '20rem',
    height: '100vh',
    left: '0',
    top: '0',
    overflowX: 'hidden',
    paddingTop: '20rem',
    textAlign: 'center',
  }

  return (
    <section style={containerLg}>
      <aside style={side}>
        {/* <Link to="/dashboard/Payment" className="btn btn-link">Payment</Link><br /> */}
        {data?.Items[0].isContentCreator.BOOL && (
          <Link to="/dashboard/Editor" className="btn btn-link">Editor</Link>
        )}
        <Button variant="link" onClick={toggleModal}>Buy Coins!</Button>
        <Button variant="link">Delete Account</Button>
      </aside>
      <aside style={containerLg}>
        <h1>Welcome to the dashboard!</h1>
      </aside>
      <aside style={containerInGrid}>
        <Button variant="dark" onClick={removeCustomerId}>Remove Payment Method</Button>
      </aside>
      {!data?.Items[0].isContentCreator.BOOL && (
        <aside style={containerInGrid}>
          <Button variant="dark" onClick={toggleCreatorModal}>Register as a Content Creator</Button>
        </aside>
      )}
      {data?.Items[0].isContentCreator.BOOL && (
        <UserArticles />
      )}
      {showModal && (
        <CoinsModal showModal={showModal} toggleModal={toggleModal} />
      )}
      {(showRegister && !data?.Items[0].isContentCreator.BOOL) && (
        <CreatorRegister email={app.authAttributes?.email!} fname={app.authAttributes?.given_name!} 
                          lname={app.authAttributes?.family_name!} sub={app.authAttributes?.sub!}
                          showModal={showRegister} toggleModal={toggleCreatorModal}/>
      )}
    </section>
  );
}

export default DashHome;