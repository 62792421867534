import { FocusEvent, useRef, useState } from 'react';
import { Form, Button, Modal, Toast } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useCognitoContext } from '../hooks/cognitoContext';
import { containerMid } from '../style/ts/containers';
import { toastTopRight } from '../style/ts/toasts';

interface NewPasswordTypes {
  email: string
  verificationCode: string
  newPassword: string
  newPasswordVerify: string
}

const NewPassword = () => {

  const history = useHistory();
  const cognito = useCognitoContext();
  const [showDialog, setShowDialog] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const credentials = useRef({} as NewPasswordTypes);

  const handleInput = (e: FocusEvent<HTMLInputElement> | undefined) => {
    e?.preventDefault();
    if(e && credentials.current) credentials.current[`${e?.target.name}` as keyof NewPasswordTypes] = e?.target.value;
    console.log('data: ', credentials.current, e?.target.name, e?.target.value);
  }

  const handlePasswordRequest = () => {
    cognito.forgotPassword(credentials.current.email)
      .then(res => {
        console.log('res: ', res);
        setShowDialog(true);
      }).catch(err => {
        console.error('err: ', err);
        alert('Sorry but we don\'t have that email in our database.')
      });
  }

  const handleNewPassword = () => {
    if(credentials.current.newPassword !== credentials.current.newPasswordVerify) {
      alert('Sorry but the passwords aren\'t matching...please make sure the new password matches the verify password.');
      return;
    }
    cognito.confirmPassword(credentials.current)
      .then(res => {
        console.log('new: ', res);
        setShowDialog(false);
        setShowToast(true);
      }).catch(err => console.error('new err: ', err));
  }

  const handleDialogClose = () => {
    setShowDialog(false);
  }

  const handleToastClose = () => {
    setShowToast(false);
    history.push('/itzli/Login');
  }

  return (
    <section style={containerMid}>
      <h1>Forgot your password? <em>No se preocupe!</em></h1>
      <br />
      <Form>
        <Form.Group>
          <Form.Label>What's your email address?</Form.Label>
          <Form.Control name="email" type="email" placeholder="Email" onBlur={handleInput} />
        </Form.Group>
        <Button onClick={handlePasswordRequest} variant="dark" type="button">Request New Password <span role="img" aria-label="fire" >🔥</span></Button>
      </Form>
      {showDialog && (
        <Modal show={showDialog} onHide={handleDialogClose} backdrop="static">
          <Modal.Title>Please enter your confirmation credentials below:</Modal.Title>
          <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Verification Code</Form.Label>
              <Form.Control name="verificationCode" type="password" placeholder="Verification Code" onBlur={handleInput} />
            </Form.Group>
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <Form.Control name="newPassword" type="password" placeholder="New Password" onBlur={handleInput} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Verify Password</Form.Label>
              <Form.Control name="newPasswordVerify" type="password" placeholder="Verify Password" onBlur={handleInput} />
            </Form.Group>
            <Modal.Footer>
              <Button onClick={handleNewPassword} variant="dark" type="button">Submit <span role="img" aria-label="fire" >🔥</span></Button>
            </Modal.Footer>
          </Form>
          </Modal.Body>
        </Modal>
      )}
      {showToast && (
        <Toast style={toastTopRight} show={showToast} onClose={handleToastClose}>
          <Toast.Header>
            You're all set!
          </Toast.Header>
          <Toast.Body>
          <em>Ahora</em> it's time to log in!
          </Toast.Body>
        </Toast>
      )}
    </section>
  )
}

export default NewPassword;