import { FocusEvent, useRef, useEffect, useState } from 'react';
import { Form, Button, Modal, Toast } from 'react-bootstrap';
import { containerMid } from '../style/ts/containers';
import { toastTopRight } from '../style/ts/toasts';
import { useCognitoContext } from '../hooks/cognitoContext';
import { RegistrationDataTypes, VerificationTypes } from '../services/cognito-service';

const Signup = () => {
  const cognito = useCognitoContext();
  const [showDialog, setShowDialog] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const signupData = useRef<RegistrationDataTypes>({} as RegistrationDataTypes);
  const verificationData = useRef<VerificationTypes>({} as VerificationTypes);

  const handleInput = (e: FocusEvent<HTMLInputElement> | undefined) => {
    e?.preventDefault();
    if(e && signupData.current) signupData.current[`${e?.target.name}` as keyof RegistrationDataTypes] = e?.target.value;
    console.log('data: ', signupData.current, e?.target.name, e?.target.value);
  }

  const handleVerificationInput = (e: FocusEvent<HTMLInputElement> | undefined) => {
    e?.preventDefault();
    if(e && verificationData.current) verificationData.current[`${e?.target.name}` as keyof VerificationTypes] = e?.target.value;
    verificationData.current.email = signupData.current.email;
    console.log('verificationData: ', verificationData.current);
  }

  const handleDialogClose = () => {
    setShowDialog(false);
  }

  const handleToastClose = () => {
    setShowToast(false);
  }

  const handleRegister = () => {

    // TODO: validation

    // get recaptcha token, then register (token will be evaluated in backend)
    window.grecaptcha.ready( () => {
      console.log('checking if bot...');
      window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_PUBLIC, {action: 'submit'}).then( async (token: string) => {
          if(token) {
            const signUpResult = await cognito.signUp(signupData.current, token)
            if(signUpResult) {
              console.log('res: ', signUpResult);
              if(typeof signUpResult !== 'string') setShowDialog(true);
              else alert('Something went wrong. Please try again later.');
            }
          }
      });
    });
  }

  const verifyUser = async () => {
    await cognito.confirmUser(verificationData.current.verificationCode,verificationData.current.email).then(
      res => {
        console.log('res: ', res);
        handleDialogClose();
        setShowToast(true);
      },
      err => {
        alert(err.message);
      }
    );
  }

  useEffect(() => {
    // load recaptcha script, if not yet loaded
    const loadScriptByURL = (id: string, url: string, callback: () => void) => {
      const isScriptExist = document.getElementById(id);

      if(!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = () => {
          if(callback) callback();
        }
        document.body.appendChild(script);
      }

      if(isScriptExist && callback) callback();
    }

    // load script by passing url
    loadScriptByURL('recaptcha-key', process.env.REACT_APP_RECAPTCHA_URL!, () => {
      console.log('recaptcha loaded');
    });
  })

  return (
    <section style={containerMid}>
      <h1>Register to become a content creator with Ítzli!</h1>
      <Form>
        <Form.Group>
          <Form.Label>First Name</Form.Label>
          <Form.Control name="given_name" type="text" placeholder="First Name" onBlur={handleInput} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Last Name</Form.Label>
          <Form.Control name="family_name" type="text" placeholder="Last Name" onBlur={handleInput} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control name="email" type="email" placeholder="Enter Email" onBlur={handleInput} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control name="password" type="password" placeholder="Password" onBlur={handleInput} />
          <Form.Text>This site is protected by reCAPTCHA. Google's <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</Form.Text>
        </Form.Group>
        <Button onClick={handleRegister} variant="dark" type="button">
          Register <span role="img" aria-label="fire">🔥</span>
        </Button>
        <Form.Group>
          <Form.Label>
            The password must, at minimum, be 8 characters, and must have at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter.
          </Form.Label>
        </Form.Group>
      </Form>
      {
        showDialog && (
          <Modal show={showDialog} onHide={handleDialogClose} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Verification code needed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Verification Code</Form.Label>
                <Form.Control name="verificationCode" onBlur={handleVerificationInput} type="text" placeholder="Enter Code" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={verifyUser} variant="primary">Verify</Button>
            </Modal.Footer>
          </Modal>
        )
      }
      {
        showToast && (
          <Toast style={toastTopRight} show={showToast} onClose={handleToastClose}>
            <Toast.Header>
              You're now registered with Ítzli!
            </Toast.Header>
            <Toast.Body>
              Now, go and make <em>la magia</em> happen!
            </Toast.Body>
          </Toast>
        )
      }
    </section>
  );
}

export default Signup;