import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ArticleStateTypes } from './Article';
import Avatar from 'react-avatar';
import '../style/css/ArticleSide.css';
import CoinTransfer from './CoinTransfer';

const ArticleSide = ({ fname, lname, pdate }: ArticleStateTypes) => {

  const [showSupport, setShowSupport] = useState(false);

  const handleSupport = () => {
    setShowSupport(true);
  }

  const closeSupport = () => {
    setShowSupport(false);
  }

  return (
    <aside className="sidebar">
      <h5>Author -</h5>
      <Avatar size="60" round title={fname} name={`${fname} ${lname}`} />
      <p className="fw-light text-capitalize d-inline ml-3">{fname} {lname}</p>
      <p className="fw-light mt-2">Published: {new Date(pdate.toUpperCase()).toLocaleDateString()}</p>
      <Button onClick={handleSupport} size="sm" variant="outline-light">Send Coins</Button>
        {showSupport && 
          <CoinTransfer show={showSupport} handleClose={closeSupport} />
        }
    </aside>
  );
}

export default ArticleSide;