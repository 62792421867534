import { FC, useState, useRef, ChangeEvent, MouseEvent, FocusEvent, useEffect } from 'react';
import { Button, Popover, Form, Container, OverlayTrigger, Tooltip, ButtonToolbar } from 'react-bootstrap';
import { ChatQuoteFill, ListUl, ListOl, CodeSquare, EmojiHeartEyesFill, PaintBucket } from 'react-bootstrap-icons';
import { EditorState, RichUtils, convertToRaw, Modifier, CompositeDecorator, SelectionState, DraftHandleValue, convertFromRaw } from 'draft-js';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import PublishModal from './PublishModal';
import { handleHighlight, colorStyleMap, blockStyling } from './CustomStyles';
import { useApiContext } from '../../hooks/apiContext';
import { useAppContext } from '../../hooks/providerContext';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createImagePlugin from '@draft-js-plugins/image';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createResizablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import { ContentBlock, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import '../../style/css/MainEditor.css';
import '../../style/css/Titles.css';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import { readFile } from '../../utilities/read-file';
import { getFirstParagraph } from '../../utilities/get-first-paragraph';
import { saveS3Params, getS3Params, clearS3Params } from '../../utilities/local-storage-s3-params';
import { ArticlesTypes } from '../Articles';

interface CustomComponentTypes {
  contentState: ContentState
  entityKey: string
}

const findLinkEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const Link: FC<CustomComponentTypes> = ({ children, contentState, entityKey }) => {
  const {url} = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} style={{textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export const strategyDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;
const resizeablePlugin = createResizablePlugin();
const focusPlugin = createFocusPlugin();
const blockDndPlugin = createBlockDndPlugin();
const decorator = composeDecorators(
    resizeablePlugin.decorator,
    focusPlugin.decorator,
    alignmentPlugin.decorator,
    blockDndPlugin.decorator
);

const linkifyPlugin = createLinkifyPlugin();
const imagePlugin = createImagePlugin({decorator});
const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions } = emojiPlugin;

const plugins = [linkifyPlugin, imagePlugin, focusPlugin, resizeablePlugin, alignmentPlugin, emojiPlugin, blockDndPlugin];

interface EditArticleMetaDataTypes {
  etag: string
  bucketKey: string
}

interface MainEditorTypes {
  placeholder: string
  editing: boolean
  data?: number[]
  editArticleMetaData?: EditArticleMetaDataTypes
  editArticleData?: ArticlesTypes
}

// MAIN COMPONENT
const MainEditor = ({placeholder, editing, data, editArticleMetaData, editArticleData}: MainEditorTypes) => {

  const articleContent = data ? convertFromRaw(JSON.parse(String.fromCharCode(...data))) : undefined;

  const api = useApiContext();
  const app = useAppContext();
  const [editorState, setEditorState] = useState<EditorState>(() => EditorState.createEmpty());
  const [readOnly, setReadOnly] = useState(false);
  const [currentStyle, setCurrentStyle] = useState(editorState.getCurrentInlineStyle());
  const [currentBlocktype, setCurrentBlocktype] = useState('unstyled');
  const [currentFuntype, setCurrentFuntype] = useState('unstyled');
  const [postSaving, setPostSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const editorRef = useRef<Editor | null>(null);

  const tags = useRef<string[]>([]);
  const genre = useRef<string[]>([]);

  useEffect(() => {
    setCurrentStyle(editorState.getCurrentInlineStyle());
    setCurrentBlocktype(editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType());
    setCurrentFuntype(editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType());
    return () => {
      if(showModal) {
        setShowModal(false);
        clearS3Params();
      }
    }
  }, [editorState, showModal]);

  const savePost = async (isPublished: boolean) => {
    if(postSaving) return;
    console.log('saving! ');
    setPostSaving(true);
    if(editorState.getCurrentContent().getBlocksAsArray().length < 2) return;
    console.log('continuing on');
    // the raw state, stringified
    const rawEditorContent = convertToRaw(editorState.getCurrentContent());
    const description = getFirstParagraph(editorState.getCurrentContent().getBlocksAsArray());

    const articleMetaData = editing ? editArticleMetaData : getS3Params();
    await api.postArticle(rawEditorContent, editorState.getCurrentContent().getBlocksAsArray()[0].getText(), {isPublished: isPublished, description: description, tags: tags.current, genre: genre.current, ...articleMetaData}, app.authAttributes)
      .then(res => {
        console.log('post article res: ', res);
        saveS3Params({etag: res.etag, bucketKey: res.bucketKey});
        if(isPublished) setShowModal(true);
      }).catch(err => console.error('err: ', err));
    setPostSaving(false);
  }

  const handleInput = (e: FocusEvent<HTMLInputElement>) => {
    const splitStr = e.target.value.split(/,\s|\s|,/g); // regex handles splitting comma-space separated, space separated, and comma separated
    e.target.name === 'tags' ? tags.current = splitStr : genre.current = splitStr;
    console.log('tags ', 'genre ', tags.current, genre.current);
  }

  const addLlink = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const link = prompt('Enter url here...');
    if(!link) return;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
        'LINK', 'MUTABLE', {url: link}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(
        contentStateWithEntity,
        editorState.getSelection(),
        entityKey
    );
    const styledContentStateWithLink = Modifier.applyInlineStyle(
        contentStateWithLink,
        editorState.getSelection(),
        'HYPERLINK'
    );
    const newState = EditorState.set(editorState, {
        currentContent: styledContentStateWithLink,
        decorator: strategyDecorator
    });

    setEditorState(RichUtils.toggleLink(newState, newState.getSelection(), entityKey));
  }
  
  // hot keys (ex: cmd/ctl + B = 'Bold')
  const handleKeyCommand = (command: string, editorState: EditorState) => {
    console.log('command: ', command);
    const newState = RichUtils.handleKeyCommand(editorState, command);
    
    if(newState) {
      setEditorState(newState);
      return 'handled';
    }
    
    return 'not-handled';
  }
  
  const handleDroppedFiles = (selection: SelectionState, files: Blob[]): DraftHandleValue => {
    console.log('dropped: ', selection, files);
    if(files && files.length > 1) {
      alert('Only 1 file at a time, please :)');
      return 'not-handled';
    }
    
    readFile(files, imagePlugin, editorState, setEditorState);

    return 'handled';
  }

  const handleFiles = (files: FileList | null) => {
    if(files && files.length > 1) {
      alert('Only 1 file at a time, please :)');
      return 'not-handled';
    }
    
    readFile(files, imagePlugin, editorState, setEditorState);

    return 'handled';
  }
  
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title>
        Add an image!
      </Popover.Title>
      <Popover.Content>
        <Form.File onChange={(e: ChangeEvent<HTMLInputElement>) => handleFiles(e.target.files)} label="Choose a file, or drag an image into the editor.">
        </Form.File>
      </Popover.Content>
    </Popover>
  )
  
  const _addRichStyle = (e: MouseEvent<HTMLButtonElement>, type: string) => {
    console.log('styling! ', type);
    e.preventDefault();
    const newState = RichUtils.toggleInlineStyle(editorState, type); // type = 'BOLD', 'ITALICS', etc.
    setEditorState(newState);
  }
  
  const _addBlockType = (e: ChangeEvent<HTMLSelectElement> | MouseEvent<HTMLButtonElement>, type: string) => {
    console.log('type: ', type);
    e.preventDefault();
    const newState = RichUtils.toggleBlockType(editorState, type);
    setEditorState(newState);
  }
  
  const HEADING_BLOCK_TYPES = [
    {label: 'Title', style: 'header-one'},
    {label: 'Subtitle', style: 'header-two'},
    {label: 'Heading', style: 'header-three'},
    {label: 'Subheading', style: 'header-four'},
    {label: 'Subheading 2', style: 'header-five'},
    {label: 'Subheading 3', style: 'header-six'},
    {label: 'Body', style: 'unstyled'}
  ];

  const FUN_TYPES = [
    {label: 'Flashy', style: 'flashy'},
    {label: 'Unbreakable', style: 'unbreakable'},
    {label: 'Accordian', style: 'accordian'},
    {label: 'Zoomer', style: 'zoomer'},
    {label: 'Sizzle', style: 'sizzle'},
    {label: 'Shapeshift', style: 'shapeshift'},
  ]
  
  const BLOCK_TYPES = [
    {label: <ChatQuoteFill />, style: 'blockquote'},
    {label: <ListUl />, style: 'unordered-list-item'},
    {label: <ListOl />, style: 'ordered-list-item'}
  ];

  return (
    <section className="main-root">
      {!readOnly && 
        <Container className="toolbar-container" fluid>
          <ButtonToolbar>
              <Button active={currentStyle.has('BOLD')} variant="outline-light" title="Bold" onMouseDown={(e: MouseEvent<HTMLButtonElement>) => _addRichStyle(e, 'BOLD')}><strong>B</strong></Button>
              <Button active={currentStyle.has('ITALIC')} variant="outline-light" title="Italic" onMouseDown={(e: MouseEvent<HTMLButtonElement>) => _addRichStyle(e, 'ITALIC')}><em>I</em></Button>
              <Button active={currentStyle.has('UNDERLINE')} variant="outline-light" title="Underline" onMouseDown={(e: MouseEvent<HTMLButtonElement>) => _addRichStyle(e, 'UNDERLINE')}><u>U</u></Button>
              <Button active={currentStyle.has('CODE')} variant="outline-light" title="Code Block" onMouseDown={(e: MouseEvent<HTMLButtonElement>) => _addRichStyle(e, 'CODE')}><CodeSquare /></Button>
              <Button active={currentStyle.has('HIGHLIGHT')} variant="outline-light" title="Highlight" onMouseDown={(e: MouseEvent<HTMLButtonElement>) => handleHighlight(e, editorState, setEditorState, 'HIGHLIGHT')} className="highlighter"><PaintBucket /></Button>
              <Form.Control as="select" className="dropdown-medium" value={currentBlocktype} onChange={(e: ChangeEvent<HTMLSelectElement>) => _addBlockType(e, e.target.value)}>
                {HEADING_BLOCK_TYPES.map((t, i) => <option key={i} value={t.style}>{t.label}</option>)}
              </Form.Control>
              <Form.Control as="select" placeholder="Titles" className="dropdown-medium" value={currentFuntype} onChange={(e: ChangeEvent<HTMLSelectElement>) => _addBlockType(e, e.target.value)}>
                <option value="unstyled">Titles</option>
                {FUN_TYPES.map((t, i) => <option key={i} value={t.style}>{t.label}</option>)}
              </Form.Control>
              {BLOCK_TYPES.map((t, i) => <Button key={i} variant="outline-light" title={t.style} active={t.style === currentBlocktype} onMouseDown={(e) => _addBlockType(e as MouseEvent<HTMLButtonElement>, t.style)}>{t.label}</Button>)}
              <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                {({ref, ...triggerHandler}) => (
                  <Button ref={ref} {...triggerHandler} variant="outline-light" title="Image">Img</Button>
                )}
              </OverlayTrigger>
              <Button variant="outline-light" title="Hyperlink" onMouseDown={addLlink}>Link</Button>
              <OverlayTrigger placement="top"
                overlay={<Tooltip id="tooltip-top">Create emoji by typing a colon (:)</Tooltip>}
              >
                {({ref, ...triggerHandler}) => (
                  <Button ref={ref} {...triggerHandler} variant="outline-light"><EmojiHeartEyesFill /></Button>
                )}
              </OverlayTrigger>
          </ButtonToolbar>
        </Container>
      }
      <aside className="main-editor" onClick={() => {editorRef.current?.focus()}}>
          <Editor 
              editorState={articleContent ? EditorState.createWithContent(articleContent, strategyDecorator) : editorState} 
              onChange={setEditorState}
              customStyleMap={colorStyleMap}
              blockStyleFn={blockStyling}
              textAlignment="left"
              spellCheck={true}
              readOnly={readOnly}
              placeholder={placeholder}
              plugins={plugins}
              handleKeyCommand={handleKeyCommand}
              handleDroppedFiles={handleDroppedFiles}
              ref={editorRef}
          />
      </aside>
      <AlignmentTool />
      <EmojiSuggestions />
      <hr />
      <aside style={{padding: '1rem'}}>
          <Button disabled={postSaving} variant="outline-light" onClick={() => savePost(true)}>Publish <span role="img" aria-label="fire">🔥</span></Button>
          <Button disabled={postSaving} variant="outline-light" onClick={() => savePost (false)}>Save</Button>
          <Button variant="outline-light" onClick={() => setReadOnly(!readOnly)} >{readOnly ? 'Disable Preview' : 'Preview'}</Button>
          <Form style={{width: '30rem', paddingTop: '1rem'}}>
            <Form.Group>
              <Form.Label>Tags</Form.Label>
              <Form.Control defaultValue={editArticleData?.tags.L.toString() ?? undefined} name="tags" type="text" placeholder="tags" maxLength={140} onBlur={handleInput} />
              <Form.Label>Genre(s)</Form.Label>
              <Form.Control defaultValue={editArticleData?.genre.L.toString() ?? undefined} name="genres" type="text" placeholder="genres" maxLength={140} onBlur={handleInput} />
            </Form.Group>
          </Form>
      </aside>
      {showModal &&
        <PublishModal show={showModal} />
      }
    </section>
  );
}

export default MainEditor;