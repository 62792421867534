import { FocusEvent, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCognitoContext } from '../hooks/cognitoContext';
import { containerMid } from '../style/ts/containers';
import { useAppContext } from '../hooks/providerContext';

interface LoginTypes {
  email: string
  password: string
}

const Login = () => {
  const app = useAppContext();
  const cognito = useCognitoContext();
  const loginData = useRef({} as LoginTypes);

  const handleInput = (e: FocusEvent<HTMLInputElement> | undefined) => {
    e?.preventDefault();
    if(e && loginData.current) loginData.current[`${e?.target.name}` as keyof LoginTypes] = e?.target.value;
    console.log('data: ', loginData.current, e?.target.name, e?.target.value);
  }

  const handleAuthentication = () => {
    cognito.authenticate(loginData.current.email, loginData.current.password)
    .then(res => {
      console.log('res: ', res);
      app.login && app.login();
    })
    .catch(err => alert('Rayos! Something went wrong 🙀. Please try again later.'));
  }

  return (
    <section style={containerMid}>
      <h1>Sign In to Ítzli</h1>
      <Form>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control name="email" type="email" placeholder="Email" onBlur={handleInput} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control name="password" type="password" placeholder="Password" onBlur={handleInput} />
        </Form.Group>
        <Button onClick={handleAuthentication} variant="dark" type="button">Login <span role="img" aria-label="fire" >🔥</span></Button>
      </Form>
      <br />
      <Link to="/itzli/NewPassword">Forgot your password?</Link>
    </section>
  )
}

export default Login;