import { Button, Card, Spinner } from "react-bootstrap";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router";
import { useApiContext } from "../hooks/apiContext";
import { DynamoBooleanType, DynamoStringType, DynamoListStringType } from "./types/dynamo-types";

export interface ArticlesTypes {
  id: DynamoStringType
  etag: DynamoStringType
  author: DynamoStringType
  description: DynamoStringType
  fname: DynamoStringType
  lname: DynamoStringType
  isPublished: DynamoBooleanType
  title: DynamoStringType
  publishedDate: DynamoStringType
  updatedDate: DynamoStringType
  genre: DynamoListStringType
  tags: DynamoListStringType
}

export interface ArticlesItemsTypes {
  Items: ArticlesTypes[]
  NextToken: string | undefined
  message?: string
}

const Articles = () => {

  const api = useApiContext();
  const history = useHistory();

  const sendToArticle = (id: string, author: string, fname: string, lname: string, pdate: string) => {
    // use history, send to article using path (don't forget to figure this out in router)
    console.log('sending to: ', id);
    history.push({
      pathname: `/itzli/Article/${id}`,
      state: {author, fname, lname, pdate}
    });
  } 

  const { data, isError, isSuccess, isLoading, isFetching, refetch, fetchNextPage, hasNextPage } = useInfiniteQuery<ArticlesItemsTypes>('articles-data', async () => {
    return await api.getArticles().then(res => res).catch(err => {throw new Error(err)});
  }, {
    getNextPageParam: (lastPage) => {
      console.log('last page: ', lastPage);
      return lastPage.NextToken;
    },
    refetchOnWindowFocus: false,
    retry: 2
  }); 

  if(isLoading || isFetching) return <Spinner animation="grow" />;

  if(isSuccess && typeof data?.pages[0].message !== 'string') return (
    <section>
      <h1 className="ml-4 mb-3 p-3">Articles</h1>
      <aside className="ml-5" style={{width: '50rem'}}>
        {data?.pages[0].Items.map(({id, title, description, fname, lname, author, publishedDate}) => {
          return (<Card key={id.S} style={{marginTop: '1.5rem'}}>
            {/* <Card.Header as="h5">Featured</Card.Header> */}
            <Card.Body>
              <Card.Title className="text-capitalize">{title.S}</Card.Title>
              <Card.Text>
                {description.S}
              </Card.Text>
              <Button variant="link" onClick={() => sendToArticle(id.S, author.S, fname.S, lname.S, publishedDate.S)}>Read More</Button>
              <footer className="blockquote-footer text-capitalize">
                By: {fname.S} {lname.S} - {new Date(publishedDate.S.toUpperCase()).toLocaleDateString()}
              </footer>
            </Card.Body>
          </Card>);
        })}
      </aside>
    </section>
  );

  if(isError) return <h1>An error has occurred. Please come back again later.</h1>;

  else return <h1>An error has occurred. Please come back again later.</h1>;
}

export default Articles;