import { useState } from 'react';
import { Modal, ListGroup, Button } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { ModalTypes } from './CoinsModal';
import '../style/css/CreatorRegister.css';

export interface CreatorRegisterModalTypes extends ModalTypes {
  email: string
  fname: string
  lname: string
  sub: string
}

const CreatorRegister = ({email, fname, lname, sub, showModal, toggleModal}: CreatorRegisterModalTypes) => {

  const api = useApiContext();
  const [btnDisabled, setBtnDisabled] = useState(false);

  const mutation = useMutation(async () => {
    setBtnDisabled(true);
    await api.creatorRegister(email, fname, lname, sub).then(res => {
      console.log('creator res: ', res);
      return res;
    }).catch(err => {throw new Error(err)});
  }, {
    onSuccess: () => {
      console.log('success!');
      setBtnDisabled(false)
      alert('¡Chivaso! You did it! You are officially a content creator with your very own Stripe account, which is connected to Íztli! Check your email :)');
      // refresh?
      toggleModal();
    },
    onError: (e: Error) => {
      console.log('error! ', e.message);
      alert('I\'m very sorry, but your account was not created. Please try again soon.');
      setBtnDisabled(false)
      toggleModal();
    },
    onSettled: () => console.log('settled')
  });

  const createAccount = () => {
    mutation.mutate();
  }

  return (
    <>
      <Modal className="bg-dark" contentClassName="bg-dark text-light" show={showModal} onHide={toggleModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="roboto-slab-bold">Content Creator Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>If you would like to register as a <strong>content creator</strong>, we would like to note the following:</p>
          <ListGroup variant="flush" className="bg-dark">
            <ListGroup.Item variant="dark">You must be 16 years or older in age</ListGroup.Item>
            <ListGroup.Item variant="info">A Stripe Connected account will be created for you in order to manage your payouts</ListGroup.Item>
            <ListGroup.Item variant="dark">You must have a bank account/debit card in order to get your Itzli Coin payouts</ListGroup.Item>
            <ListGroup.Item variant="dark">Your content will remain with Iztli for a minimum of 5 years, unless you decide to delete it, or update it (which will extend it to another 5 years)</ListGroup.Item>
          </ListGroup>
          <p>If you're okay with the above stipulations, please click on register.</p>
          <Button disabled={btnDisabled} onClick={createAccount} variant="outline-light">Register <span role="img" aria-label="fire">🔥</span></Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreatorRegister;