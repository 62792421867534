import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../../style/css/PublishModal.css';

interface PublishModalTypes {
  show: boolean
}

const PublishModal = ({ show }: PublishModalTypes) => {

  const history = useHistory();

  const handleClose = () => {
    history.push('/'); // eventually send to dashboard
  }

  return (
    <Modal
      contentClassName="modal-dark"
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Article Published!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        If you wish to make any edits in the future, you can do that via the Ítzli Dashboard!
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-light" onClick={handleClose}>Chevere!</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PublishModal;