import { MouseEvent, SetStateAction } from 'react';
import { EditorState, ContentState, Modifier, RichUtils, ContentBlock } from 'draft-js';

export interface CustomComponentTypes {
  contentState: ContentState
  entityKey: string
}

export const blockStyling = (block: ContentBlock): string => {
  const type = block.getType();
  switch (type) {
    case 'blockquote':
      return 'superFancyBlockquote';
    case 'flashy':
      return 'flashy';
    case 'unbreakable':
      return 'unbreakable';
    case 'accordian':
      return 'accordian';
    case 'zoomer':
      return 'zoomer';
    case 'sizzle':
      return 'sizzle';
    case 'shapeshift':
      return 'shapeshift';
  
    default:
      return 'not-handled'
  }
}

// STYLEMAP

export const colorStyleMap = {
  'HIGHLIGHT': {
    backgroundColor: 'yellow'
  }
}

// HANDLERS

export const handleHighlight = (e: MouseEvent<HTMLButtonElement>, editorState: EditorState, setEditorState: (value: SetStateAction<EditorState>) => void, style: string) => {
  e.preventDefault();
  const selection = editorState.getSelection();

  // Let's just allow one color at a time. Turn off current hightlight.
  const nextContentState = Object.keys(colorStyleMap)
    .reduce((contentState, style) => {
      return Modifier.removeInlineStyle(contentState, selection, style)
    }, editorState.getCurrentContent());

  let nextEditorState = EditorState.push(
    editorState,
    nextContentState,
    'change-inline-style'
  );

  const currentStyle = editorState.getCurrentInlineStyle();

  // Unset style override for current color.
  if (selection.isCollapsed()) {
    nextEditorState = currentStyle.reduce((state: EditorState | undefined, style: string | undefined) => {
      return RichUtils.toggleInlineStyle(state as EditorState, style as string);
    }, nextEditorState);
  }

  // If the color is being toggled on, apply it.
  if (!currentStyle.has(style)) {
    nextEditorState = RichUtils.toggleInlineStyle(
      nextEditorState,
      style
    );
  }

  setEditorState(nextEditorState);
}