const isCorrectMimeType = (type: string) => {
  return ['png', 'jpg', 'jpeg', 'gif'].includes(type.split('/')[1]);
}

export const decreaseImgBytes = (b64: string, mimeType: string): Promise<string | undefined> => {
  return new Promise ((resolve, reject) => {
      if(!isCorrectMimeType(mimeType)) reject('Incorrect file type. Please use one of the following types: png, jpg, jpeg, or gif.');
      let quality = 0.8;
      const img = new Image();
      img.src = b64;
      img.onload = () => {
          const elem = document.createElement('canvas');

          switch(true) {
              case (b64.length < 10000):
                  quality = 0.9;
                  elem.width = img.width * 0.95;
                  elem.height = img.height * 0.95;
                  break;
              case (b64.length < 25000):
                  elem.width = img.width * 0.85;
                  elem.height = img.height * 0.85;
                  break;
              case (b64.length < 50000):
                  elem.width = img.width * 0.75;
                  elem.height = img.height * 0.75;
                  break;
              case (b64.length < 75000):
                  quality = 0.75;
                  elem.width = img.width * 0.65;
                  elem.height = img.height * 0.65;
                  break;
              case (b64.length >= 75000):
                  quality = 0.65;
                  elem.width = img.width * 0.5;
                  elem.height = img.height * 0.5;
                  break;
              default:
                  return reject('Something went wrong with image compression.');
          }
          
          const ctx = elem.getContext('2d');
          ctx?.drawImage(img, 0, 0, img.width, img.height);
  
          const data = ctx?.canvas.toDataURL(mimeType, quality);

          resolve(data);
      }
  });
}