import { Fragment } from 'react';
import MainEditor from './editors/MainEditor';

const Editor = () => {
  return (
    <Fragment>
      <MainEditor placeholder='Start with a title, the editor will keep up!' editing={false} />
    </Fragment>
  );
}

export default Editor;