export const saveS3Params = (obj: {[key: string]: string}) => {
  localStorage.setItem('currentS3Params', JSON.stringify(obj));
}

export const getS3Params = () => {
  const s3Params = localStorage.getItem('currentS3Params');
  return typeof s3Params === 'string' ? JSON.parse(s3Params) : undefined;
}

export const clearS3Params = () => {
  localStorage.removeItem('currentS3Params');
}