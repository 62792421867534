import { CSSProperties } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import coin from '../resources/images/skull-coin.gif';

export interface CoinsTypes {
  numberOfCoins: number
  amount: number
}

const CoinsItem = ({numberOfCoins, amount}: CoinsTypes) => {

  const history = useHistory();

  const sendToPurchase = (numberOfCoins: number, amount: number) => {
    history.push('/dashboard/Payment', {
      numberOfCoins: numberOfCoins,
      amount: amount
    });
  }

  const coinImg: CSSProperties = {
    maxWidth: '6rem'
  }

  return (
    <Container>
      <Row>
        <Col lg="3" md="3" sm="6">
          <Image style={coinImg} src={coin} alt="coin" roundedCircle />
        </Col>
        <Col lg="9" md="9" sm="6">
          <h2>{numberOfCoins} coins: {`$${amount / 100}`}</h2>
          <Button variant="dark" className="roboto-slab" onClick={() => sendToPurchase(numberOfCoins, amount)}>YAAS!</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default CoinsItem;