import { useParams } from 'react-router';

import Home from '../components/Home';
import Articles from '../components/Articles';
import Signup from '../components/Signup';
import Login from '../components/Login';
import NewPassword from '../components/NewPassword';

const Main = () => {
  const { name }: {name: string} = useParams();
  console.log('name: ', name);
  return (
    <main role="main">
      {(() => {
        switch (name) {
          case 'Home':
            return <Home />
          case 'Articles':
            return <Articles />
          case 'Register':
            return <Signup />
          case 'Login':
            return <Login />
          case 'NewPassword':
            return <NewPassword />
          default:
            return;
        }
      })()}
    </main>
  );
}

export default Main;