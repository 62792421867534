import { Navbar, Nav } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from '../hooks/providerContext';

interface AppPageTypes {
  title: string
  path: string
}

const ItzliNav = () => {
  
  const app = useAppContext();
  const history = useHistory();

  const logout = () => {
    app.logout && app.logout();
  }

  const navigateTo = (path: string) => {
    history.push(path);
  }

  const pages = ['Home', 'Articles', 'Register', 'Login'];
  const appPages = pages.reduce<AppPageTypes[] | undefined>((prev, curr) => {
    if(app.auth && ['Register', 'Login'].includes(curr as string)) return prev;
    else return [...(prev as AppPageTypes[]), {title: curr, path: `/itzli/${curr}`}];
  },[]);

  return (
    <Navbar style={{zIndex: 200}} collapseOnSelect bg="dark" variant="dark">
      <Navbar.Brand><Link style={{color: 'inherit'}} to="/">Ítzli</Link></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {appPages?.map(({title, path}, index) => {
            return <Link key={index} style={{color: 'lightgray', margin: '0 0.5rem'}} to={path}>{title}</Link>
          })
          }
        </Nav>
        {app.auth && (
          <Nav>
            <Nav.Link onClick={() => navigateTo('/dashboard')} >Dashboard</Nav.Link>
            <Nav.Link onClick={logout}>Logout</Nav.Link>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default ItzliNav;