import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AppProvider from './AppProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import ItzliNav from './components/ItzliNav';
import Main from './pages/Main';
import Dashboard from './pages/Dashboard';
import Article from './components/Article';
import EditArticle from './components/EditArticle';

const stripePromise = loadStripe(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_STRIPE_TEST_API! : '');

function App() {
  const queryClient = new QueryClient();
  return (
    <BrowserRouter>
        <Switch>
          <Elements stripe={stripePromise}>
            <AppProvider>
              <QueryClientProvider client={queryClient}>
                <ItzliNav />
                <Route path="/" exact>
                  <Redirect to="/itzli/Home" />
                </Route>
                <Route path="/itzli/:name">
                  <Main />
                </Route>
                <Route path="/dashboard" exact>
                  <Dashboard />
                </Route>
                <Route path="/dashboard/:name">
                  <Dashboard />
                </Route>
                <Route path="/itzli/Article/:id">
                  <Article />
                </Route>
                <Route path="/itzli/EditArticle/:id">
                  <EditArticle />
                </Route>
              </QueryClientProvider>
            </AppProvider>
          </Elements>
        </Switch>
      </BrowserRouter>
  );
}

export default App;
