import Editor from "@draft-js-plugins/editor";
import { EditorState, convertFromRaw } from 'draft-js';
import { blockStyling } from './CustomStyles';
import { colorStyleMap } from './CustomStyles';
import { strategyDecorator } from './MainEditor';
import 'draft-js/dist/Draft.css';
import { useState } from 'react';
import '../../style/css/MainEditor.css';
import '../../style/css/ReadOnlyEditor.css';
import 'draft-js/dist/Draft.css';
import { ArticleTypes } from '../Article';


const ReadOnlyEditor = ({data}: ArticleTypes) => {
  const articleContent = convertFromRaw(JSON.parse(String.fromCharCode(...data)));
  const [editorState, setEditorState] = useState<EditorState>(() => EditorState.createEmpty());

  return (
    <aside className="ro-editor">
        <Editor 
            editorState={EditorState.createWithContent(articleContent, strategyDecorator) ?? editorState}
            onChange={setEditorState}
            customStyleMap={colorStyleMap}
            blockStyleFn={blockStyling}
            textAlignment="left"
            readOnly={true}
        />
    </aside>
  );
}

export default ReadOnlyEditor;