import * as AWSCognito from 'amazon-cognito-identity-js';
import { createContext } from 'react';

export interface RegistrationDataTypes {
  given_name: string
  family_name: string
  email: string
  password: string
}

export interface VerificationTypes {
  email: string,
  verificationCode: string,
  newPassword: string
}

export interface AuthAttributeTypes {
  authDetails: AWSCognito.AuthenticationDetails
  cognitoUser: AWSCognito.CognitoUser
}

interface HttpHeaders {
  [key: string]: string;
}

class CognitoService {
  
  _POOL_DATA: AWSCognito.ICognitoUserPoolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID!,
    ClientId: process.env.REACT_APP_CLIENT_ID!
  };  
  
  _userPool: AWSCognito.CognitoUserPool;

  _headers: HttpHeaders;

  constructor() {
    this._userPool = new AWSCognito.CognitoUserPool(this._POOL_DATA);
    this._headers = { 'Content-Type': 'application/json' };
    if(process.env.NODE_ENV !== 'production') this._headers['X-API-KEY'] = process.env.REACT_APP_API_KEY!;
  }

  signUp = async (registrationData: RegistrationDataTypes, recaptchaToken: string) => {
    const params = new URLSearchParams({
      action: 'register'
    });
    return await fetch(`${process.env.REACT_APP_COGNITO_URL}?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({registrationData: registrationData, recaptchaToken: recaptchaToken})
    }).then(res => res.json())
    .catch(err => {throw err});
  }

  confirmUser = async (verificationCode: string, email: string) => {
    const params = new URLSearchParams({
      action: 'confirm'
    })
    
    return await fetch(`${process.env.REACT_APP_COGNITO_URL}?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({verificationCode: verificationCode, email: email})
    }).then(res => res.json())
    .catch(err => {throw err});
  }

  authenticate = (email: string, password: string) => {
    return new Promise((resolved, reject) => {

      const authDetails = new AWSCognito.AuthenticationDetails({
        Username: email,
        Password: password
      });

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: this._userPool
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: result => {
          resolved(result);
        },
        onFailure: err => {
          reject(err);
        },
        newPasswordRequired: userAttributes => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          userAttributes.email = email;
          delete userAttributes.email_verified;

          cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
            onSuccess: () => {},
            onFailure: (error) => {
              reject(error);
            }
          });
        }
      });
    });
  }
  isUserAuthenticated = () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
      if(!cognitoUser) return reject(false);
      cognitoUser.getSession((err: Error | null) => {
        // console.log('stashed jwt token: ', session.getIdToken().getJwtToken());
        if(err) return reject(false);
        resolve(true);
      });
    });
  }

  getUserAttrs = (): Promise<AWSCognito.CognitoUserAttribute[] | undefined> => {
    return new Promise((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
  
      // getSession must be called to authenticate user before calling getUserAttributes
      cognitoUser?.getSession((err: Error | null, session: AWSCognito.CognitoUserSession | null) => {
        if(err) return reject(err);
        console.log(`session validity: ${session?.isValid()}`);
    
        cognitoUser.getUserAttributes((err, attrs) => {
          if(err) return reject(err);
          resolve(attrs);
        });
      });
    });
  }

  logout = () => {
    const cognitoUser = this._userPool.getCurrentUser();
    cognitoUser?.signOut();
  }

  // FORGOT PASSWORD FLOW
  forgotPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: this._userPool
      });

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          // successfully initiated reset password request
          console.log(`CodeDeliveryData from forgotPassword: ${data}`);
          resolve(data);
        },
        onFailure: (err) => {
          alert(err.message ?? JSON.stringify(err));
          reject(err);
        }
      })
    });
  }

  confirmPassword = ({email, verificationCode, newPassword}: VerificationTypes) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: this._userPool
      });

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve(true);
        },
        onFailure(err) {
          console.error('failed confirming pw: ', err);
          reject(false);
        }
      })
    });
  }
  // END FORGOT PASSWORD FLOW
}

// set up Cognito context (Cognito package will take care of state)
const csInstance = new CognitoService();
const CognitoContext = createContext(csInstance);

export default CognitoContext;