import { createContext } from 'react';
import { RawDraftContentState } from 'draft-js';
import { CognitoUserAttributes } from '../AppProvider';
import { PaymentMethod } from '@stripe/stripe-js';

export interface HttpHeaders {
  [key: string]: string;
}

interface ArticleDataTypes {
  isPublished: boolean
  description: string
  tags: string[]
  genre: string[]
  etag: string | null
}

interface PaymentDataTypes {
  numberOfCoins: number
  amount: number
  saveCard: boolean
  paymentMethod: PaymentMethod | undefined
  sub: string | undefined
  customerId: string | undefined
  paymentMethodId: string | undefined
}

class ApiService {

  _headers: HttpHeaders;
  
  constructor() {
    console.log('constructing service');
    this._headers = { 'Content-Type': 'application/json' };
    if(process.env.NODE_ENV !== 'production') this._headers['X-API-KEY'] = process.env.REACT_APP_API_KEY!;
  }

  getCustomer = async (sub: string | undefined) => {
    if(!sub) {throw new Error('User id not provided.')}
    const params = new URLSearchParams({
      getType: 'customer',
      sub: sub
    });

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_STRIPE_CRUD + `?${params}` : `/v1/stripe-crud?${params}`, {
      method: 'GET',
      headers: this._headers, 
    }).then(res => res.json())
    .catch(err => {throw new Error(err)});
  }

  postArticle = async (rawData: RawDraftContentState, title: string, articleData: ArticleDataTypes, authAttributes: CognitoUserAttributes | undefined) => {
    if(!authAttributes) {throw new Error('User attributes not provided.')};
    const params = new URLSearchParams({
      type: 'article'
    });

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_ARTICLE_URL + `?${params}` : `/v1/writen-content?${params}`, {
      method: 'POST',
      headers: this._headers, 
      body: JSON.stringify({rawData: rawData, title: title, authAttributes: authAttributes, ...articleData})
    }).then(res => res.json())
    .catch(err => {throw new Error(err)});
  } 

  makePurchase = async (paymentMethod: PaymentMethod | undefined, saveCard: boolean, numberOfCoins: number, amount: number, sub: string | undefined, customerId: string | undefined, paymentMethodId: string | undefined) => {
    if(!sub) {throw new Error('User id not provided.')}
    const paymentObject: PaymentDataTypes = {sub: sub, numberOfCoins: numberOfCoins, amount: amount, saveCard: saveCard, paymentMethod: paymentMethod, customerId: customerId, paymentMethodId: paymentMethodId};
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_STRIPE_URL! : `/v1/itzli-payment`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(paymentObject)
    }).then(res => res.json())
    .catch(err => {throw new Error(err)});
  }

  getCoinPrices = async () => {
    const params = new URLSearchParams({
      getType: 'coins',
    });

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_STRIPE_CRUD + `?${params}` : `/v1/stripe-crud?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => res.json())
    .catch(err => {throw new Error(err)});
  }

  removeCustomerId = async (customerId: string | undefined, sub: string | undefined) => {
    if(!sub || !customerId) {throw new Error('User/customer id not provided.')}
    const params = new URLSearchParams({
      getType: 'customer'
    });

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_STRIPE_CRUD + `?${params}` : `/v1/stripe-crud?${params}`, {
      method: 'DELETE',
      headers: this._headers,
      body: JSON.stringify({customerId: customerId, sub: sub})
    }).then(res => {
      console.log('delete res: ', res);
      return res.json()
    })
    .catch(err => {
      console.log('delete err: ', err);
      throw new Error(err)
    });
  }

  getArticles = async () => {
    const params = new URLSearchParams({
      getType: 'articles'
    });

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_ARTICLE_URL + `?${params}` : `/v1/writen-content?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => res.json())
    .catch(err => {throw new Error(err)});
  }

  getSingleArticle = async (id: string) => {
    const params = new URLSearchParams({
      getType: 'article',
      article: id
    });

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_ARTICLE_URL + `?${params}` : `/v1/writen-content?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => res.json())
    .catch(err => {throw new Error(err)});
  }

  coinExchange = async (donorId: string, recieverId: string, numOfCoinsToExchange: number) => {
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_EXCHANGE! : `/v1/coin-exchange`, {
      method: 'PUT',
      headers: this._headers,
      body: JSON.stringify({donorId: donorId, recieverId: recieverId, numOfCoinsToExchange: numOfCoinsToExchange})
    }).then(res => res.json())
    .catch(err => {throw new Error(err)});
  }

  creatorRegister = async (email: string, fname: string, lname: string, sub: string) => {
    if(!sub) {throw new Error('User id not provided.')};

    const params = new URLSearchParams({
      getType: 'account'
    });

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_STRIPE_CRUD + `?${params}` : `/v1/stripe-crud?${params}` , {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({email, fname, lname, sub})
    }).then(res => res.json())
    .catch(err => {throw new Error(err)});
  }

  getUserArticles = async (sub: string) => {
    if(!sub) {throw new Error('User id not provided.')};

    const params = new URLSearchParams({
      getType: 'user-articles',
      id: sub
    });

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_ARTICLE_URL + `?${params}` : `/v1/writen-content?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => res.json())
    .catch(err => {throw new Error(err)});
  }

}

const ApiInstance = new ApiService();

export default createContext(ApiInstance);