import { Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import Editor from '../components/Editor';
import Payment from '../components/Payment';
import DashHome from '../components/DashHome';
import { DynamoStringType, DynamoNumberType, DynamoBooleanType } from '../components/types/dynamo-types';
import { useApiContext } from '../hooks/apiContext';
import { useAppContext } from '../hooks/providerContext';

interface CustomerTypes {
  customerId: DynamoStringType
  paymentMethodId: DynamoStringType
  id: DynamoStringType
  numberOfCoins: DynamoNumberType
  isContentCreator: DynamoBooleanType
}

export interface CustomerItemTypes {
  Items: CustomerTypes[]
}

const Dashboard = () => {
  const api = useApiContext();
  const app = useAppContext();
  
  const {data, isSuccess, isLoading, isFetching} = useQuery<CustomerItemTypes>('customerData', async () => {
    return await api.getCustomer(app.authAttributes?.sub).then(res => res).catch(err => {throw new Error(err)});
  }, {
    retry: 2,
    refetchOnWindowFocus: false
  })

  const { name }: {name: string} = useParams();
  if(isLoading || isFetching) return <Spinner animation="grow" />;

  else if(isSuccess) return (
    <main role="main">
      {(() => {
        switch (name) {
          case 'Editor':
            return <Editor />
          case 'Payment':
            return <Payment data={data} />
          default:
            console.log('default');
            return <DashHome data={data} sub={app.authAttributes?.sub} />
        }
      })()}
    </main>
  );

  else return <h1>An error has occurred. Please come back again later.</h1>;
}

export default Dashboard;