import { decreaseImgBytes } from './image-compression';
import { EditorState } from 'draft-js';
import { ImageEditorPlugin } from '@draft-js-plugins/image/lib/index'

export const readFile = (files: Blob[] | FileList | null, imagePlugin: ImageEditorPlugin, editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
    const fileReader = new FileReader();
    
    if(files) fileReader.readAsDataURL(files[0] as Blob);
    else return;

    fileReader.onload = async () => {
      await decreaseImgBytes(fileReader.result as string, (files[0] as Blob).type)
      .then((res: string | undefined) => { // expecting base64 string
        console.log('file length: ', res?.length);
        if(res && res.length > 1000000) { // 1MB image limit
          alert('The image file is too large. Please use an image that is smaller, preferably less than 1MB.');
        }
        const newState = imagePlugin.addImage(editorState, res as string, {});
        setEditorState(newState);
      }).catch(err => {
        alert(err)
      });
    }  
}