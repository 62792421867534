import { Button, Card, Spinner } from "react-bootstrap";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router";
import { useApiContext } from "../hooks/apiContext";
import { useAppContext } from "../hooks/providerContext";
import { ArticlesItemsTypes, ArticlesTypes } from "./Articles";

const UserArticles = () => {

  const api = useApiContext();
  const app = useAppContext();
  const history = useHistory();

  const sendToEdit = (id: string, items: ArticlesTypes) => {
    // use history, send to article using path (don't forget to figure this out in router)
    console.log('editing: ', id);
    history.push({
      pathname: `/itzli/EditArticle/${id}`,
      state: items
    });
  } 

  const { data, isError, isSuccess, isLoading, isFetching } = useInfiniteQuery<ArticlesItemsTypes>('user-articles-data', async () => {
    return await api.getUserArticles(app.authAttributes?.sub ?? '').then(res => res).catch(err => {throw new Error(err)});
  }, {
    getNextPageParam: (lastPage) => {
      console.log('last page: ', lastPage);
      return lastPage.NextToken;
    },
    refetchOnWindowFocus: false,
    retry: 2
  }); 

  if(isLoading || isFetching) return <Spinner animation="grow" />;

  if(isSuccess) return (
    <section>
      <h1 className="ml-4 mb-3 p-3">Articles</h1>
      <aside className="ml-5" style={{width: '50rem'}}>
        {data?.pages[0].Items.map((i) => {
          return (<Card key={i.id.S} style={{marginTop: '1.5rem'}}>
            {/* <Card.Header as="h5">Featured</Card.Header> */}
            <Card.Body>
              <Card.Title className="text-capitalize">{i.title.S}</Card.Title>
              <Card.Text>
                {i.description.S}
              </Card.Text>
              <Button variant="link" onClick={() => sendToEdit(i.id.S, i)}>Edit</Button>
              <footer className="blockquote-footer text-capitalize">
                By: {i.fname.S} {i.lname.S} - {new Date(i.publishedDate.S).toLocaleDateString()}
              </footer>
            </Card.Body>
          </Card>);
        })}
      </aside>
    </section>
  );

  if(isError) return <h1>An error has occurred. Please come back again later.</h1>;

  else return <h1>An error has occurred. Please come back again later.</h1>;
}

export default UserArticles;