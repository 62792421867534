import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router';
import { useApiContext } from '../hooks/apiContext';
import CoinTransfer from './CoinTransfer';
import MainEditor from '../components/editors/MainEditor';
import '../style/css/ReadOnlyEditor.css';
import { ArticleParamTypes, ArticleTypes } from './Article';
import { ArticlesTypes } from './Articles';

const EditArticle = () => {
  
  const api = useApiContext();
  const params = useParams<ArticleParamTypes>();
  const { state } = useLocation<ArticlesTypes>();
  console.log('arties: ', state);

  const { data, isError, isSuccess, isLoading, isFetching, error } = useQuery<ArticleTypes>('current-article', async () => {
    return await api.getSingleArticle(params.id).then(res => res).catch(err => {throw new Error(err)});
  }, {
    refetchOnWindowFocus: false,
    retry: 2
  });

  if(isLoading || isFetching) return <Spinner animation="grow" />

  if(isSuccess) return (
    <section className="ro-root">
      {console.log('data: ', (data && data.data) ? String.fromCharCode(...data?.data): 'blah')}
      {(data && data.data) ? <MainEditor placeholder='Article was not found :(' editing={true} data={data?.data} editArticleData={state} editArticleMetaData={{etag: state.etag.S, bucketKey: state.id.S}} /> 
        : <h1>The article had a problem loading.</h1>}
    </section>
  );

  else if (isError) return <h1>Error!!! {console.log('error ', error)}</h1>
  else return <h1>Error! (We should make an error component)</h1>
}

export default EditArticle;