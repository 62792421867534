import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router';
import { useApiContext } from '../hooks/apiContext';
import ReadOnlyEditor from './editors/ReadOnlyEditor';
import ArticleSide from './ArticleSide';
import '../style/css/ReadOnlyEditor.css';

export interface ArticleParamTypes {
  id: string
}

export interface ArticleStateTypes {
  author?: string
  fname: string
  lname: string
  pdate: string
}

export interface ArticleTypes {
  data: number[]
}

const Article = () => {
  
  const api = useApiContext();
  const params = useParams<ArticleParamTypes>();
  const { state: { fname, lname, pdate } } = useLocation<ArticleStateTypes>();

  const { data, isError, isSuccess, isLoading, isFetching, error } = useQuery<ArticleTypes>('current-article', async () => {
    return await api.getSingleArticle(params.id).then(res => res).catch(err => {throw new Error(err)});
  }, {
    refetchOnWindowFocus: false,
    retry: 2
  });

  if(isLoading || isFetching) return <Spinner animation="grow" />

  if(isSuccess) return (
    <>
      <article className="ro-root">
        <ArticleSide fname={fname} lname={lname} pdate={pdate} />
        {/* {console.log('data: ', (data && data.data) ? String.fromCharCode(...data?.data) : 'blah')} */}
        {(data && data.data) ? <ReadOnlyEditor data={data?.data} /> 
          : <h1>The article had a problem loading.</h1>}
      </article>
    </>
  );

  else if (isError) return <h1>Error!!! {console.log('error ', error)}</h1>
  else return <h1>Error! (We should make an error component)</h1>
}

export default Article;